import React, { useState, useEffect } from "react";

import {
  Box,
  Grid,
  Typography,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import { Add, ChevronLeft, ChevronRight } from "@mui/icons-material";

import { useAdpContextController } from "../context";

// Menu data import
import ScheduleData from "../data/ScheduleData";
import menuBtn from "../assets/icons/menu_btn.png";

import "./schedule.css";

function Schedule() {
  const [controller] = useAdpContextController();
  const { language } = controller;

  const switcher = ScheduleData();

  const savedState = window.localStorage.getItem("savedState");

  const [scheduleState, setScheduleState] = useState(
    savedState === null ? switcher.menu : switcher[savedState]
  );
  const [ripple, setRipple] = useState(switcher.week0);
  const [open, setOpen] = useState("");

  useEffect(() => {
    setScheduleState(
      savedState === null ? switcher.menu : switcher[savedState]
    );
  }, [language]);

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    setOpen(false);
  }, [scheduleState]);

  const container = {
    pt: "1rem",
    backgroundColor: "#e6e3db",
    height: "auto",
  };

  const divider = {
    mx: "auto",
    height: "1px",
    width: "75%",
    backgroundColor: "#333333",
  };

  const dividerLight = {
    mx: "auto",
    mb: "1rem",
    height: "1px",
    width: "85%",
    backgroundColor: "#fff",
  };

  const dividerWeek = {
    mx: "auto",
    height: "1px",
    width: "55%",
    backgroundColor: "#333333",
  };

  const column1 = {
    px: { xs: "2rem", sm: "5rem" },
  };

  const responsiveImg = {
    width: "100%",
    height: "auto",
  };

  const menuIcon = {
    width: "5rem",
    height: "5rem",
    cursor: "pointer",
  };

  const menuBox = {
    display: { xs: "block", sm: "none" },
    position: "fixed",
    bottom: "0",
    right: "0",
    padding: "1rem",
  };

  const header1 = {
    fontFamily: "brandon-grotesque",
    fontSize: { xs: "2rem", sm: "2.5rem" },
    fontWeight: "bold",
    color: "#333333",
    mb: "1rem",
  };

  const para1 = {
    fontFamily: "ff-basic-gothic-pro",
    display: "inline",
    textAlign: "center",
    // mt: ".35rem",
    fontSize: "1rem",
    wordBreak: "break-word",
    wordWrap: "break-word",
    hyphens: "auto",
  };

  const para2 = {
    fontFamily: "ff-basic-gothic-pro",
    textAlign: "left",
    mt: ".35rem",
    fontSize: "1rem",
    wordBreak: "break-word",
    wordWrap: "break-word",
    hyphens: "auto",
  };

  const paraSwitch = {
    fontFamily: "ff-basic-gothic-pro",
    fontSize: { xs: "1rem", sm: "1.5rem" },
    py: ".75rem",
    px: { xs: "1rem", sm: "0" },
    opacity: "0.5",
    cursor: "pointer",
  };

  const cross = {
    color: "#dac0a8",
  };

  const btn = {
    display: "block",
    mt: "1rem",
    backgroundColor: "#fff",
    color: "#333",
    "&:hover": {
      backgroundColor: "#f5f5f5",
    },
    border: "solid 1px #d46e28",
    borderRadius: "0",
  };

  const accordion = {
    color: "#fff",
    backgroundColor: "#3c3d3b",
    border: "solid 2px #6c5037",
  };

  const footerBox = {
    fontFamily: "ff-basic-gothic-pro",
    padding: "1rem",
    border: "solid 2px #d57532",
    height: "auto",
  };

  const arrow = {
    color: "#da813f",
    fontSize: "4rem",
    cursor: "pointer",
  };

  const arrowDisabled = {
    color: "#da813f",
    fontSize: "4rem",
    cursor: "pointer",
    opacity: "0.5",
  };

  const stateSwitcher = (x) => {
    if (x === "") {
      return;
    } else {
      const newState = x
        .toLowerCase()
        .replace(/\s/g, "")
        .replace("-", "")
        .replace("visit", "");

      window.localStorage.setItem("savedState", newState);

      setScheduleState(switcher[newState]);
      setRipple(newState);
    }
  };

  const icons = scheduleState.icons.map((el) => (
    <>
      {scheduleState.style === "menu" && (
        <Grid item xs={12} sm={4} md={2.4} key={el.title}>
          <div
            onMouseOver={() => {
              const newState = el.title
                .toLowerCase()
                .replace(/\s/g, "")
                .replace("-", "")
                .replace("visit", "");
              setRipple(el.switch);
            }}
            className={ripple}
          >
            <img src={el.icon} alt={el.title} style={responsiveImg} />
          </div>
          <Button
            variant="contained"
            sx={btn}
            onClick={() => {
              stateSwitcher(el.switch);
            }}
            fullWidth
          >
            <strong
              style={{
                textTransform:
                  language.name === "Spanish" || language.name === "Catalan"
                    ? "none"
                    : "capitalize",
              }}
            >
              {el.title}
            </strong>
          </Button>
        </Grid>
      )}
      {scheduleState.style === "week" && (
        <Grid item xs={12} sm={4} md={2.4}>
          <div className={ripple}>
            <img src={el.icon} alt={el.title} style={responsiveImg} />
          </div>
          <Accordion
            expanded={open === el.title}
            onClick={() => {
              if (open === el.title) {
                setOpen("");
              } else {
                setOpen(el.title);
              }
            }}
            sx={accordion}
          >
            <AccordionSummary>
              <Add sx={cross} />
              <Typography variant="p" sx={para1}>
                {el.title}
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ textAlign: "left" }}>
              <Box sx={dividerLight} />
              <Typography variant="p" sx={para2}>
                {scheduleState.title ===
                  "Semana 0 (Periodo de tratamiento del estudio)" &&
                el.title === "Reconocimiento físico"
                  ? language.schedule_scope_details_esp
                  : el.details}
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Grid>
      )}
    </>
  ));

  return (
    <Box sx={container}>
      {scheduleState.style === "menu" && (
        <Grid container sx={{ padding: { xs: "0", sm: "2rem" } }}>
          <Grid item xs={12} sx={column1}>
            <Typography variant="h1" component="h1" sx={header1}>
              {language.schedule_menu_title}
            </Typography>
            <Box sx={divider} />
            <Typography component="p" sx={para2}>
              {language.schedule_menu_subtitle1}
            </Typography>
            <Typography component="p" sx={para2}>
              {language.schedule_menu_subtitle2}
            </Typography>
          </Grid>
        </Grid>
      )}
      {scheduleState.style === "week" && (
        <>
          {/* Desktop */}
          <Box sx={{ display: { xs: "none", sm: "block" } }}>
            <Grid container sx={{ padding: { xs: "0", sm: "2rem" } }}>
              <Grid item xs={2}>
                <Typography
                  component="p"
                  onClick={() => {
                    stateSwitcher(scheduleState.backLink);
                  }}
                  sx={paraSwitch}
                >
                  {scheduleState.backTitle}
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <ChevronLeft
                  sx={scheduleState.backLink === "" ? arrowDisabled : arrow}
                  onClick={() => {
                    stateSwitcher(scheduleState.backLink);
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="h1" component="h1" sx={header1}>
                  {scheduleState.title}
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <ChevronRight
                  sx={scheduleState.forwardLink === "" ? arrowDisabled : arrow}
                  onClick={() => {
                    stateSwitcher(scheduleState.forwardLink);
                  }}
                />
              </Grid>
              <Grid item xs={2}>
                <Typography
                  component="p"
                  onClick={() => {
                    stateSwitcher(scheduleState.forwardLink);
                  }}
                  sx={paraSwitch}
                >
                  {scheduleState.forwardTitle}
                </Typography>
              </Grid>
              <Box sx={dividerWeek} />
            </Grid>
            <Grid container sx={{ px: "2rem" }}>
              <Grid item>
                <Typography component="p" sx={para2}>
                  {scheduleState.para1}
                </Typography>
                <Typography component="p" sx={para2}>
                  {scheduleState.para2}
                </Typography>
              </Grid>
            </Grid>
          </Box>
          {/* Mobile */}
          <Box sx={{ display: { xs: "block", sm: "none" } }}>
            <Grid container sx={{ padding: { xs: "0", sm: "2rem" } }}>
              <Grid item xs={12}>
                <Typography variant="h1" component="h1" sx={header1}>
                  {scheduleState.title}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Box sx={dividerWeek} />
              </Grid>
              <Grid item xs={4}>
                <Typography component="p" sx={paraSwitch}>
                  {scheduleState.back}
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <ChevronLeft
                  sx={scheduleState.back === "" ? arrowDisabled : arrow}
                  onClick={() => {
                    stateSwitcher(scheduleState.back);
                  }}
                />
              </Grid>
              <Grid item xs={2}>
                <ChevronRight
                  sx={scheduleState.forward === "" ? arrowDisabled : arrow}
                  onClick={() => {
                    stateSwitcher(scheduleState.forward);
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <Typography component="p" sx={paraSwitch}>
                  {scheduleState.forward}
                </Typography>
              </Grid>
            </Grid>
            <Grid container sx={{ px: "1rem" }}>
              <Grid item>
                <Typography component="p" sx={para2}>
                  {scheduleState.para1}
                </Typography>
                <Typography component="p" sx={para2}>
                  {scheduleState.para2}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </>
      )}
      <Grid container p={4} spacing={4} sx={column1}>
        {icons}
      </Grid>
      {scheduleState.style === "week" && (
        <Grid container p={4}>
          <Grid item xs={2} p={6} sx={{ display: { xs: "none", sm: "flex" } }}>
            <img
              src={menuBtn}
              alt="Menu button"
              onClick={() => {
                stateSwitcher("menu");
              }}
              style={menuIcon}
            />
          </Grid>
          <Box sx={menuBox}>
            <img
              src={menuBtn}
              alt="Menu button"
              onClick={() => {
                stateSwitcher("menu");
              }}
              style={menuIcon}
            />
          </Box>
          <Grid item xs={12} sm={10} sx={{ my: "auto" }}>
            <Box sx={footerBox}>
              {scheduleState.footerBox1}
              <br />
              {scheduleState.footerBox2}
            </Box>
          </Grid>
        </Grid>
      )}
    </Box>
  );
}

export default Schedule;
