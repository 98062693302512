const btn = {
  display: "block",
  mt: "1rem",
  backgroundColor: "#fff",
  color: "#333",
  "&:hover": {
    backgroundColor: "#f5f5f5",
  },
  border: "solid 1px #d46e28",
  borderRadius: "0",
  textTransform: "capitalize",
};

const dot = {
  color: "#d46e28",
  height: ".8rem",
  p: ".2rem",
};

export { btn, dot };
