import React, { useState, useEffect, createRef } from "react";

import { Box, Grid, Typography } from "@mui/material";
import { TripOrigin, FiberManualRecord, ArrowBack } from "@mui/icons-material";

import { useAdpContextController } from "../context";

import lottie from "lottie-web";

import Graphic from "../assets/graphics/study_responsibilities_graphic.png";
import ResponsibilitiesAnimation from "../assets/animJson/05_StudyResponsibilities.json";
import AdditionalAnimation from "../assets/animJson/07_Pills.json";
import AdditionalGraphic from "../assets/graphics/additional_responsibilities_graphic.png";

function Responsibilities() {
  const [controller] = useAdpContextController();
  const { language } = controller;

  const [page, setPage] = useState(false);

  useEffect(() => {
    const anim = lottie.loadAnimation({
      container: animationContainer.current,
      loop: false,
      autoplay: true,
      animationData: page ? AdditionalAnimation : ResponsibilitiesAnimation,
    });

    // anim.playSegments([[0, 15]], true);
    // anim.playSegments([[15, 60]], true);

    return () => anim.destroy();
  }, [page]);

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [page]);

  const animationContainer = createRef();

  const container = {
    backgroundColor: "#e6e3db",
    height: "auto",
  };

  const divider = {
    mx: "auto",
    height: "1px",
    width: "75%",
    backgroundColor: "#333333",
  };

  const column1 = {
    p: { xs: "1rem", sm: "3rem", md: "5rem" },
  };

  const column2 = {
    mt: { xs: "0", sm: "5rem" },
  };

  const image = {
    width: "100%",
    height: "auto",
  };

  const additionalImage = {
    height: "400px",
    p: "2rem",
  };

  const header1 = {
    fontFamily: "brandon-grotesque",
    fontSize: "2.5rem",
    fontWeight: "bold",
    color: "#333333",
    mb: "1rem",
  };

  const para = {
    fontFamily: "ff-basic-gothic-pro",
    textAlign: "left",
    my: "1rem",
    fontSize: "1rem",
    color: "#333333",
  };

  const footer = {
    fontFamily: "ff-basic-gothic-pro",
    textAlign: "center",
    my: "1rem",
    fontSize: "1rem",
    color: "#333333",
  };

  const marginTop = {
    marginTop: ".5rem",
  };

  const blank = {
    listStyle: "none",
    textAlign: "left",
    paddingLeft: "1rem",
    fontFamily: "ff-basic-gothic-pro",
  };

  const dot = {
    color: "#d46e28",
    height: ".8rem",
    p: ".2rem",
  };

  const link = {
    color: "#d46e28",
    textDecoration: "underline",
    cursor: "pointer",
  };

  return (
    <Box sx={container}>
      {page === false ? (
        <Grid container p={2}>
          <Grid item xs={12} sm={7} sx={column1}>
            <Typography variant="h1" component="h1" sx={header1}>
              {language.responsibilities_title}
            </Typography>
            <Box sx={divider} />
            <Typography component="p" sx={para}>
              {language.responsibilities_subtitle}
            </Typography>
            <ul style={blank}>
              <li style={marginTop}>
                <Grid container>
                  <Grid item xs={0.6}>
                    <FiberManualRecord sx={dot} />
                  </Grid>
                  <Grid item xs={11.4}>
                    {language.responsibilities_list_item1}
                  </Grid>
                </Grid>
              </li>
              <li style={marginTop}>
                <Grid container>
                  <Grid item xs={0.6}>
                    <FiberManualRecord sx={dot} />
                  </Grid>
                  <Grid item xs={11.4}>
                    {language.responsibilities_list_item2}
                  </Grid>
                </Grid>
              </li>
              <li style={marginTop}>
                <Grid container>
                  <Grid item xs={0.6}>
                    <FiberManualRecord sx={dot} />
                  </Grid>
                  <Grid item xs={11.4}>
                    {language.responsibilities_list_item3}
                  </Grid>
                </Grid>
              </li>
              <li style={marginTop}>
                <Grid container>
                  <Grid item xs={0.6}>
                    <FiberManualRecord sx={dot} />
                  </Grid>
                  <Grid item xs={11.4}>
                    {language.responsibilities_list_item4}
                  </Grid>
                </Grid>
              </li>
              <li style={marginTop}>
                <Grid container>
                  <Grid item xs={0.6}>
                    <FiberManualRecord sx={dot} />
                  </Grid>
                  <Grid item xs={11.4}>
                    {language.responsibilities_list_item5}
                  </Grid>
                </Grid>
              </li>
              <li style={marginTop}>
                <Grid container>
                  <Grid item xs={0.6}>
                    <FiberManualRecord sx={dot} />
                  </Grid>
                  <Grid item xs={11.4}>
                    {language.responsibilities_list_item6}
                  </Grid>
                </Grid>
              </li>
              <li style={marginTop}>
                <Grid container>
                  <Grid item xs={0.6}>
                    <FiberManualRecord sx={dot} />
                  </Grid>
                  <Grid item xs={11.4}>
                    {language.responsibilities_list_item7}
                  </Grid>
                </Grid>
              </li>
              <li style={marginTop}>
                <Grid container>
                  <Grid item xs={0.6}>
                    <FiberManualRecord sx={dot} />
                  </Grid>
                  <Grid item xs={11.4}>
                    {language.responsibilities_list_item8}
                  </Grid>
                </Grid>
              </li>
            </ul>
          </Grid>
          <Grid item xs={12} sm={5} sx={column2}>
            {/* <img src={Graphic} alt="Responsibilities graphic" style={image} /> */}
            <div style={{ height: "auto" }} ref={animationContainer} />
          </Grid>
          <Grid item xs={12}>
            <Box sx={divider} />
            <Typography component="p" sx={footer}>
              {language.responsibilities_footer_pre_bold}
              <strong>{language.responsibilities_footer_bold}</strong>
              {language.responsibilities_footer_post_bold}
              <span
                style={link}
                onClick={() => {
                  setPage(!page);
                }}
              >
                {language.responsibilities_footer_link}
              </span>
              {language.responsibilities_footer_period}
            </Typography>
          </Grid>
        </Grid>
      ) : (
        <Grid container p={2}>
          <Grid item xs={12} sm={7} sx={column1}>
            <Typography variant="h1" component="h1" sx={header1}>
              {language.additional_responsibilities_title}
            </Typography>
            <Box sx={divider} />
            <ul style={blank}>
              <li style={marginTop}>
                <Grid container>
                  <Grid item xs={0.6}>
                    <FiberManualRecord sx={dot} />
                  </Grid>
                  <Grid item xs={11.4}>
                    <strong>
                      {language.additional_responsibilities_list_item1_bold}
                    </strong>
                    {language.additional_responsibilities_list_item1_post_bold}
                  </Grid>
                </Grid>
              </li>
              <li style={marginTop}>
                <Grid container>
                  <Grid item xs={0.6}>
                    <FiberManualRecord sx={dot} />
                  </Grid>
                  <Grid item xs={11.4}>
                    <strong>
                      {language.additional_responsibilities_list_item2_bold}
                    </strong>
                    {language.additional_responsibilities_list_item2_post_bold}
                  </Grid>
                </Grid>
              </li>
              <li style={marginTop}>
                <Grid container>
                  <Grid item xs={0.6}>
                    <FiberManualRecord sx={dot} />
                  </Grid>
                  <Grid item xs={11.4}>
                    <strong>
                      {language.additional_responsibilities_list_item3_bold}
                    </strong>
                    {language.additional_responsibilities_list_item3_post_bold}
                  </Grid>
                </Grid>
              </li>
              <li style={marginTop}>
                <Grid container>
                  <Grid item xs={0.6}>
                    <FiberManualRecord sx={dot} />
                  </Grid>
                  <Grid item xs={11.4}>
                    <strong>
                      {language.additional_responsibilities_list_item4_bold}
                    </strong>
                    {language.additional_responsibilities_list_item4_post_bold}
                  </Grid>
                </Grid>
                <ul style={blank}>
                  <li>
                    <Grid container>
                      <Grid item xs={0.6}>
                        <TripOrigin sx={dot} />
                      </Grid>
                      <Grid item xs={11.4}>
                        {
                          language.additional_responsibilities_list_item4_subitem
                        }
                      </Grid>
                    </Grid>
                  </li>
                </ul>
              </li>
              <li style={marginTop}>
                <Grid container>
                  <Grid item xs={0.6}>
                    <FiberManualRecord sx={dot} />
                  </Grid>
                  <Grid item xs={11.4}>
                    <strong>
                      {language.additional_responsibilities_list_item5_bold}
                    </strong>
                    {language.additional_responsibilities_list_item5_post_bold}
                  </Grid>
                </Grid>
              </li>
            </ul>
          </Grid>
          <Grid item xs={12} sm={5} sx={column2}>
            {/* <img
              src={AdditionalGraphic}
              alt="Additional responsibilities graphic"
              style={additionalImage}
            /> */}
            <div style={{ height: "auto" }} ref={animationContainer} />
          </Grid>
          <Grid item xs={12} sx={{ mt: "1rem" }}>
            <Box sx={divider} />
            <Box
              sx={{
                py: "1rem",
                fontSize: "3rem",
                height: "2rem",
                cursor: "pointer",
              }}
              onClick={() => {
                setPage(!page);
              }}
            >
              <ArrowBack />
            </Box>
          </Grid>
        </Grid>
      )}
    </Box>
  );
}

export default Responsibilities;
