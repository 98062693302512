import React, { useState, useEffect, createRef } from "react";

import { Box, Grid, Typography, Button } from "@mui/material";
import { TripOrigin, FiberManualRecord } from "@mui/icons-material";

import { btn, dot } from "../design";

import { useAdpContextController } from "../context";

import lottie from "lottie-web";

import OverviewAnimation from "../assets/animJson/01_StudyOverview.json";
import TreatmentAnimation from "../assets/animJson/02_StudyTreatmentPeriod.json";
import FollowUpAnimation from "../assets/animJson/03_FollowUpVisits.json";
import ParticipationAnimation from "../assets/animJson/04 _StudyParticipation.json";

import OverviewGraphic from "../assets/graphics/overview_graphic.png";
import TreamentGraphic from "../assets/graphics/overview_graphic_study_treatment.png";
import FollowUpGraphic from "../assets/graphics/overview_graphic_follow_up.png";
import ParticipationGraphic from "../assets/graphics/overview_graphic_participation.png";

function Overview() {
  const [controller] = useAdpContextController();
  const { language } = controller;

  const [image, setImage] = useState(OverviewGraphic);
  const [animation, setAnimation] = useState(OverviewAnimation);
  const [data, setData] = useState(0);

  const animationSwitcher = [
    {
      file: OverviewAnimation,
    },
    {
      file: TreatmentAnimation,
    },
    {
      file: FollowUpAnimation,
    },
    {
      file: ParticipationAnimation,
    },
  ];

  const animationContainer = createRef();

  useEffect(() => {
    const anim = lottie.loadAnimation({
      container: animationContainer.current,
      loop: false,
      autoplay: true,
      animationData: animationSwitcher[data].file,
    });

    // anim.playSegments([[0, 15]], true);
    // anim.playSegments([[15, 60]], true);

    return () => anim.destroy();
  }, [data]);

  const container = {
    backgroundColor: "#e6e3db",
    height: "auto",
  };

  const divider = {
    mx: "auto",
    height: "1px",
    width: "75%",
    backgroundColor: "#333333",
  };

  const column1 = {
    p: { xs: "1rem", sm: "3rem", md: "5rem" },
  };

  const column2 = {
    mt: { xs: "1rem", sm: "5rem" },
  };

  const responsiveImg = {
    width: "100%",
    height: "auto",
  };

  const marginTop = {
    marginTop: ".5rem",
  };

  const blank = {
    listStyle: "none",
    textAlign: "left",
    paddingLeft: "1rem",
    fontFamily: "ff-basic-gothic-pro",
  };

  const header1 = {
    fontFamily: "brandon-grotesque",
    fontSize: "2.5rem",
    fontWeight: "bold",
    color: "#333333",
    mb: "1rem",
  };

  const para = {
    fontFamily: "ff-basic-gothic-pro",
    textAlign: "left",
    my: "1rem",
    fontSize: "1rem",
    color: "#333333",
  };

  const setOverviewState = (x) => {
    if (data === x) {
      setData(0);
      setImage(OverviewGraphic);
    } else if (x === 1) {
      setData(1);
      setImage(TreamentGraphic);
    } else if (x === 2) {
      setData(2);
      setImage(FollowUpGraphic);
    } else if (x === 3) {
      setData(3);
      setImage(ParticipationGraphic);
    } else {
      setData(0);
      setImage(OverviewGraphic);
    }
  };

  return (
    <Box sx={container}>
      <Grid container p={2}>
        <Grid item xs={12} sm={7} sx={column1}>
          <Typography variant="h1" component="h1" sx={header1}>
            {language.overview_title}
          </Typography>
          <Box sx={divider} />
          <Typography component="p" sx={para}>
            {language.overview_subtitle}
          </Typography>
          <Button
            variant="contained"
            style={{ textTransform: "none" }}
            onClick={() => {
              setOverviewState(1);
            }}
            sx={btn}
            fullWidth
          >
            {language.overview_btn1_title}
          </Button>
          {data === 1 && (
            <ul style={blank}>
              <li>
                <FiberManualRecord sx={dot} />
                {language.overview_btn1_list_item1}
                <ul style={blank}>
                  <li>
                    <TripOrigin sx={dot} />
                    {language.overview_btn1_list_item1_subitem1}
                    <ul style={blank}>
                      <li>
                        <Grid container>
                          <Grid item xs={0.6}>
                            <FiberManualRecord sx={dot} />
                          </Grid>
                          <Grid item xs={11.4}>
                            {language.overview_btn1_list_item1_subitem1_subitem}
                          </Grid>
                        </Grid>
                      </li>
                    </ul>
                    <li>
                      <TripOrigin sx={dot} />
                      {language.overview_btn1_list_item1_subitem2}
                      <ul style={blank}>
                        <li>
                          <Grid container spacing={0}>
                            <Grid item xs={0.6}>
                              <FiberManualRecord sx={dot} />
                            </Grid>
                            <Grid item xs={11.4}>
                              {
                                language.overview_btn1_list_item1_subitem2_subitem
                              }
                            </Grid>
                          </Grid>
                        </li>
                      </ul>
                    </li>
                  </li>
                </ul>
                <li>
                  <Grid container>
                    <Grid item xs={0.6}>
                      <FiberManualRecord sx={dot} />
                    </Grid>
                    <Grid item xs={11.4}>
                      {language.overview_btn1_list_item2}
                    </Grid>
                  </Grid>
                </li>
                <li>
                  <FiberManualRecord sx={dot} />
                  {language.overview_btn1_list_item3}
                  <ul style={blank}>
                    <li>
                      {" "}
                      <TripOrigin sx={dot} />
                      {language.overview_btn1_list_item3_subitem1}
                    </li>
                    <li>
                      <TripOrigin sx={dot} />
                      {language.overview_btn1_list_item3_subitem2}
                    </li>
                  </ul>
                </li>
                <li>
                  <FiberManualRecord sx={dot} />
                  {language.overview_btn1_list_item4}
                  <ul style={blank}>
                    <li>
                      {" "}
                      <TripOrigin sx={dot} />
                      {language.overview_btn1_list_item4_subitem1}
                    </li>
                    <li style={{ display: "flex" }}>
                      <TripOrigin sx={dot} />
                      <li>{language.overview_btn1_list_item4_subitem2}</li>
                    </li>
                  </ul>
                </li>
                <li>
                  <Grid container>
                    <Grid item xs={0.6}>
                      <FiberManualRecord sx={dot} />
                    </Grid>
                    <Grid item xs={11.4}>
                      {language.overview_btn1_list_item5}
                    </Grid>
                  </Grid>
                  <ul style={blank}>
                    <li>
                      {" "}
                      <TripOrigin sx={dot} />
                      {language.overview_btn1_list_item5_subitem1}
                    </li>
                    <li>
                      <TripOrigin sx={dot} />
                      {language.overview_btn1_list_item5_subitem2}
                    </li>
                    <li>
                      <TripOrigin sx={dot} />
                      {language.overview_btn1_list_item5_subitem3}
                      <ul style={blank}>
                        <li>
                          <Grid container>
                            <Grid item xs={0.6}>
                              <FiberManualRecord sx={dot} />
                            </Grid>
                            <Grid item xs={11.4}>
                              {
                                language.overview_btn1_list_item5_subitem3_subitem
                              }
                            </Grid>
                          </Grid>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
              </li>
              <Box sx={{ mt: "1rem" }}>{language.overview_btn1_para}</Box>
            </ul>
          )}
          <Button
            variant="contained"
            style={{ textTransform: "none" }}
            onClick={() => {
              setOverviewState(2);
            }}
            sx={btn}
            fullWidth
          >
            {language.overview_btn2_title}
          </Button>
          {data === 2 && (
            <ul style={blank}>
              <li style={marginTop}>
                <Grid container>
                  <Grid item xs={0.6}>
                    <FiberManualRecord sx={dot} />
                  </Grid>
                  <Grid item xs={11.4}>
                    {language.overview_btn2_list_item1}
                  </Grid>
                </Grid>
              </li>
              <li style={marginTop}>
                <Grid container>
                  <Grid item xs={0.6}>
                    <FiberManualRecord sx={dot} />
                  </Grid>
                  <Grid item xs={11.4}>
                    {language.overview_btn2_list_item2}
                  </Grid>
                </Grid>
              </li>
              <li style={marginTop}>
                <Grid container>
                  <Grid item xs={0.6}>
                    <FiberManualRecord sx={dot} />
                  </Grid>
                  <Grid item xs={11.4}>
                    {language.overview_btn2_list_item3}
                  </Grid>
                </Grid>
              </li>
            </ul>
          )}
          <Button
            variant="contained"
            style={{ textTransform: "none" }}
            onClick={() => {
              setOverviewState(3);
            }}
            sx={btn}
            fullWidth
          >
            {language.overview_btn3_title}
          </Button>
          {data === 3 && (
            <ul style={blank}>
              <li style={marginTop}>
                <Grid container>
                  <Grid item xs={0.6}>
                    <FiberManualRecord sx={dot} />
                  </Grid>
                  <Grid item xs={11.4}>
                    {language.overview_btn3_list_item1}
                  </Grid>
                </Grid>
              </li>
            </ul>
          )}
        </Grid>
        <Grid item xs={12} sm={5} sx={column2}>
          <div style={{ height: "auto" }} ref={animationContainer} />
          {/* <img src={image} alt="Overview Page Graphic" style={responsiveImg} /> */}
        </Grid>
      </Grid>
    </Box>
  );
}

export default Overview;
