// Menu page imports
import menuWeek0 from "../assets/icons/schedule/week0.png";
import menuWeek2 from "../assets/icons/schedule/week2.png";
import menuWeek4 from "../assets/icons/schedule/week4.png";
import menuWeek8 from "../assets/icons/schedule/week8.png";
import menuWeek12 from "../assets/icons/schedule/week12.png";
import menuWeek16 from "../assets/icons/schedule/week16.png";
import menuWeek20 from "../assets/icons/schedule/week20.png";
import menuWeek24 from "../assets/icons/schedule/week24.png";
import menuWeek32 from "../assets/icons/schedule/week32.png";
import menuWeek40 from "../assets/icons/schedule/week40.png";
import menuWeek48 from "../assets/icons/schedule/week48.png";
import menuWeek52 from "../assets/icons/schedule/week52.png";
import menuFollowUp1 from "../assets/icons/schedule/followup1.png";
import menuFollowUp2 from "../assets/icons/schedule/followup2.png";

// Week0 imports
import doctorWeek0 from "../assets/icons/week0/icon1.png";
import ipadWeek0 from "../assets/icons/week0/icon2.png";
import thermWeek0 from "../assets/icons/week0/icon3.png";
import heartWeek0 from "../assets/icons/week0/icon4.png";
import scopeWeek0 from "../assets/icons/week0/icon5.png";
import tubeWeek0 from "../assets/icons/week0/icon6.png";
import bottleWeek0 from "../assets/icons/week0/icon7.png";
import thumbsWeek0 from "../assets/icons/week0/icon8.png";
import pillsWeek0 from "../assets/icons/week0/icon9.png";
import paperWeek0 from "../assets/icons/week0/icon10.png";

// Week2 imports
import doctorWeek2 from "../assets/icons/week2/icon1.png";
import ipadWeek2 from "../assets/icons/week2/icon2.png";
import thermWeek2 from "../assets/icons/week2/icon3.png";
import scopeWeek2 from "../assets/icons/week2/icon4.png";
import tubeWeek2 from "../assets/icons/week2/icon5.png";
import crossWeek2 from "../assets/icons/week2/icon6.png";
import bottleWeek2 from "../assets/icons/week2/icon7.png";
import thumbsWeek2 from "../assets/icons/week2/icon8.png";
import pillsWeek2 from "../assets/icons/week2/icon9.png";
import paperWeek2 from "../assets/icons/week2/icon10.png";

// Week4 imports
import doctorWeek4 from "../assets/icons/week4/icon1.png";
import ipadWeek4 from "../assets/icons/week4/icon2.png";
import thermWeek4 from "../assets/icons/week4/icon3.png";
import scopeWeek4 from "../assets/icons/week4/icon4.png";
import tubeWeek4 from "../assets/icons/week4/icon5.png";
import crossWeek4 from "../assets/icons/week4/icon6.png";
import bottleWeek4 from "../assets/icons/week4/icon7.png";
import thumbsWeek4 from "../assets/icons/week4/icon8.png";
import pillsWeek4 from "../assets/icons/week4/icon9.png";
import paperWeek4 from "../assets/icons/week4/icon10.png";

// Week8 imports
import doctorWeek8 from "../assets/icons/week8/icon1.png";
import ipadWeek8 from "../assets/icons/week8/icon2.png";
import thermWeek8 from "../assets/icons/week8/icon3.png";
import scopeWeek8 from "../assets/icons/week8/icon4.png";
import tubeWeek8 from "../assets/icons/week8/icon5.png";
import crossWeek8 from "../assets/icons/week8/icon6.png";
import bottleWeek8 from "../assets/icons/week8/icon7.png";
import thumbsWeek8 from "../assets/icons/week8/icon8.png";
import pillsWeek8 from "../assets/icons/week8/icon9.png";
import paperWeek8 from "../assets/icons/week8/icon10.png";

// Week12 imports
import doctorWeek12 from "../assets/icons/week12/icon1.png";
import ipadWeek12 from "../assets/icons/week12/icon2.png";
import thermWeek12 from "../assets/icons/week12/icon3.png";
import heartWeek12 from "../assets/icons/week12/icon4.png";
import scopeWeek12 from "../assets/icons/week12/icon5.png";
import breathWeek12 from "../assets/icons/week12/icon6.png";
import eyeWeek12 from "../assets/icons/week12/icon7.png";
import tubeWeek12 from "../assets/icons/week12/icon8.png";
import crossWeek12 from "../assets/icons/week12/icon9.png";
import colonWeek12 from "../assets/icons/week12/icon10.png";
import bottleWeek12 from "../assets/icons/week12/icon11.png";
import thumbsWeek12 from "../assets/icons/week12/icon12.png";
import pillsWeek12 from "../assets/icons/week12/icon13.png";
import paperWeek12 from "../assets/icons/week12/icon14.png";

// Week16 imports
import doctorWeek16 from "../assets/icons/week16/icon1.png";
import ipadWeek16 from "../assets/icons/week16/icon2.png";
import thermWeek16 from "../assets/icons/week16/icon3.png";
import scopeWeek16 from "../assets/icons/week16/icon4.png";
import tubeWeek16 from "../assets/icons/week16/icon5.png";
import bottleWeek16 from "../assets/icons/week16/icon6.png";
import thumbsWeek16 from "../assets/icons/week16/icon7.png";
import pillsWeek16 from "../assets/icons/week16/icon8.png";
import paperWeek16 from "../assets/icons/week16/icon9.png";

// Week20 imports
import doctorWeek20 from "../assets/icons/week20/icon1.png";
import ipadWeek20 from "../assets/icons/week20/icon2.png";
import thermWeek20 from "../assets/icons/week20/icon3.png";
import scopeWeek20 from "../assets/icons/week20/icon4.png";
import tubeWeek20 from "../assets/icons/week20/icon5.png";
import bottleWeek20 from "../assets/icons/week20/icon6.png";
import thumbsWeek20 from "../assets/icons/week20/icon7.png";
import pillsWeek20 from "../assets/icons/week20/icon8.png";
import paperWeek20 from "../assets/icons/week20/icon9.png";

// Week24 imports
import doctorWeek24 from "../assets/icons/week24/icon1.png";
import ipadWeek24 from "../assets/icons/week24/icon2.png";
import thermWeek24 from "../assets/icons/week24/icon3.png";
import scopeWeek24 from "../assets/icons/week24/icon4.png";
import tubeWeek24 from "../assets/icons/week24/icon5.png";
import crossWeek24 from "../assets/icons/week24/icon6.png";
import bottleWeek24 from "../assets/icons/week24/icon7.png";
import thumbsWeek24 from "../assets/icons/week24/icon8.png";
import pillsWeek24 from "../assets/icons/week24/icon9.png";
import paperWeek24 from "../assets/icons/week24/icon10.png";

// Week32 imports
import doctorWeek32 from "../assets/icons/week32/icon1.png";
import ipadWeek32 from "../assets/icons/week32/icon2.png";
import thermWeek32 from "../assets/icons/week32/icon3.png";
import scopeWeek32 from "../assets/icons/week32/icon4.png";
import breathWeek32 from "../assets/icons/week32/icon5.png";
import tubeWeek32 from "../assets/icons/week32/icon6.png";
import bottleWeek32 from "../assets/icons/week32/icon7.png";
import thumbsWeek32 from "../assets/icons/week32/icon8.png";
import pillsWeek32 from "../assets/icons/week32/icon9.png";
import paperWeek32 from "../assets/icons/week32/icon10.png";

// Week40 imports
import doctorWeek40 from "../assets/icons/week40/icon1.png";
import ipadWeek40 from "../assets/icons/week40/icon2.png";
import thermWeek40 from "../assets/icons/week40/icon3.png";
import scopeWeek40 from "../assets/icons/week40/icon4.png";
import tubeWeek40 from "../assets/icons/week40/icon5.png";
import bottleWeek40 from "../assets/icons/week40/icon6.png";
import thumbsWeek40 from "../assets/icons/week40/icon7.png";
import pillsWeek40 from "../assets/icons/week40/icon8.png";
import paperWeek40 from "../assets/icons/week40/icon9.png";

// Week48 imports
import doctorWeek48 from "../assets/icons/week48/icon1.png";
import ipadWeek48 from "../assets/icons/week48/icon2.png";
import thermWeek48 from "../assets/icons/week48/icon3.png";
import scopeWeek48 from "../assets/icons/week48/icon4.png";
import tubeWeek48 from "../assets/icons/week48/icon5.png";
import bottleWeek48 from "../assets/icons/week48/icon6.png";
import thumbsWeek48 from "../assets/icons/week48/icon7.png";
import pillsWeek48 from "../assets/icons/week48/icon8.png";
import paperWeek48 from "../assets/icons/week48/icon9.png";

// Week52 imports
import doctorWeek52 from "../assets/icons/week52/icon1.png";
import ipadWeek52 from "../assets/icons/week52/icon2.png";
import thermWeek52 from "../assets/icons/week52/icon3.png";
import heartWeek52 from "../assets/icons/week52/icon4.png";
import scopeWeek52 from "../assets/icons/week52/icon5.png";
import physicalWeek52 from "../assets/icons/week52/icon6.png";
import breathWeek52 from "../assets/icons/week52/icon7.png";
import eyeWeek52 from "../assets/icons/week52/icon8.png";
import tubeWeek52 from "../assets/icons/week52/icon9.png";
import crossWeek52 from "../assets/icons/week52/icon10.png";
import colonWeek52 from "../assets/icons/week52/icon11.png";
import bottleWeek52 from "../assets/icons/week52/icon12.png";
import thumbsWeek52 from "../assets/icons/week52/icon13.png";
import pillsWeek52 from "../assets/icons/week52/icon14.png";
import paperWeek52 from "../assets/icons/week52/icon15.png";

// Followup1 imports
import thermFollowup1 from "../assets/icons/followup1/icon1.png";
import scopeFollowup1 from "../assets/icons/followup1/icon2.png";
import breathFollowup1 from "../assets/icons/followup1/icon3.png";
import eyeFollowup1 from "../assets/icons/followup1/icon4.png";
import tubeFollowup1 from "../assets/icons/followup1/icon5.png";
import bottleFollowup1 from "../assets/icons/followup1/icon6.png";
import thumbsFollowup1 from "../assets/icons/followup1/icon7.png";

// Followup2 imports
import thermFollowup2 from "../assets/icons/followup2/icon1.png";
import scopeFollowup2 from "../assets/icons/followup2/icon2.png";
import tubeFollowup2 from "../assets/icons/followup2/icon3.png";
import bottleFollowup2 from "../assets/icons/followup2/icon4.png";
import thumbsFollowup2 from "../assets/icons/followup2/icon5.png";

// Context imports
import { useAdpContextController } from "../context";

export default function ScheduleData() {
  const [controller] = useAdpContextController();
  const { language } = controller;

  return {
    menu: {
      style: "menu",
      title: language.schedule_menu_title,
      back: "",
      forward: "",
      para1: language.schedule_menu_subtitle1,
      para2: language.schedule_menu_subtitle2,
      footerBox1: "",
      footerBox2: "",
      icons: [
        {
          icon: menuWeek0,
          title: language.schedule_btn_week0,
          switch: "week0",
        },
        {
          icon: menuWeek2,
          title: language.schedule_btn_week2,
          switch: "week2",
        },
        {
          icon: menuWeek4,
          title: language.schedule_btn_week4,
          switch: "week4",
        },
        {
          icon: menuWeek8,
          title: language.schedule_btn_week8,
          switch: "week8",
        },
        {
          icon: menuWeek12,
          title: language.schedule_btn_week12,
          switch: "week12",
        },
        {
          icon: menuWeek16,
          title: language.schedule_btn_week16,
          switch: "week16",
        },
        {
          icon: menuWeek20,
          title: language.schedule_btn_week20,
          switch: "week20",
        },
        {
          icon: menuWeek24,
          title: language.schedule_btn_week24,
          switch: "week24",
        },
        {
          icon: menuWeek32,
          title: language.schedule_btn_week32,
          switch: "week32",
        },
        {
          icon: menuWeek40,
          title: language.schedule_btn_week40,
          switch: "week40",
        },
        {
          icon: menuWeek48,
          title: language.schedule_btn_week48,
          switch: "week48",
        },
        {
          icon: menuWeek52,
          title: language.schedule_btn_week52,
          switch: "week52",
        },
        {
          icon: menuFollowUp1,
          title: language.schedule_btn_followup1,
          switch: "followup1",
        },
        {
          icon: menuFollowUp2,
          title: language.schedule_btn_followup2,
          switch: "followup2",
        },
      ],
    },
    week0: {
      style: "week",
      title: language.week0_title,
      backTitle: language.week0_back_title,
      backLink: language.week0_back,
      forwardTitle: language.week0_forward_title,
      forwardLink: language.week0_forward,
      para1: language.week0_para1,
      para2: language.week0_para2,
      footerBox1: language.week0_footer_box_line1,
      footerBox2: language.week0_footer_box_line2,
      icons: [
        {
          icon: doctorWeek0,
          title: language.schedule_doctor_title,
          ripple: "week0",
          details: language.schedule_doctor_details,
        },
        {
          icon: ipadWeek0,
          title: language.schedule_ipad_title,
          ripple: "week0",
          details: language.schedule_ipad_details,
        },
        {
          icon: thermWeek0,
          title: language.schedule_therm_title,
          ripple: "week0",
          details: language.schedule_therm_details,
        },
        {
          icon: heartWeek0,
          title: language.schedule_heart_title,
          ripple: "week0",
          details: language.schedule_heart_details,
        },
        {
          icon: scopeWeek0,
          title: language.schedule_scope_title,
          ripple: "week0",
          details: language.schedule_scope_details,
        },
        {
          icon: tubeWeek0,
          title: language.schedule_tube_title,
          ripple: "week0",
          details: language.schedule_tube_details,
        },
        {
          icon: bottleWeek0,
          title: language.schedule_bottle_title,
          ripple: "week0",
          details: language.schedule_bottle_details,
        },
        {
          icon: thumbsWeek0,
          title: language.schedule_thumbs_title,
          ripple: "week0",
          details: language.schedule_thumbs_details,
        },
        {
          icon: pillsWeek0,
          title: language.schedule_pills_title,
          ripple: "week0",
          details: language.schedule_pills_details,
        },
        {
          icon: paperWeek0,
          title: language.schedule_paper_title,
          ripple: "week0",
          details: language.schedule_paper_details,
        },
      ],
    },
    week2: {
      style: "week",
      title: language.week2_title,
      backTitle: language.week2_back_title,
      backLink: language.week2_back,
      forwardTitle: language.week2_forward_title,
      forwardLink: language.week2_forward,
      para1: language.week2_para1,
      para2: language.week2_para2,
      footerBox1: language.week2_footer_box_line1,
      footerBox2: language.week2_footer_box_line2,
      icons: [
        {
          icon: doctorWeek2,
          title: language.schedule_doctor_title,
          details: language.schedule_doctor_details,
        },
        {
          icon: ipadWeek2,
          title: language.schedule_ipad_title,
          details: language.schedule_ipad_details,
        },
        {
          icon: thermWeek2,
          title: language.schedule_therm_title,
          details: language.schedule_therm_details,
        },
        {
          icon: scopeWeek2,
          title: language.schedule_scope_title,
          details: language.schedule_scope_details,
        },
        {
          icon: tubeWeek2,
          title: language.schedule_tube_title,
          details: language.schedule_tube_details,
        },
        {
          icon: crossWeek2,
          title: language.schedule_cross_title,
          details: language.schedule_cross_details,
        },
        {
          icon: bottleWeek2,
          title: language.schedule_bottle_title,
          details: language.schedule_bottle_details,
        },
        {
          icon: thumbsWeek2,
          title: language.schedule_thumbs_title,
          details: language.schedule_thumbs_details,
        },
        {
          icon: pillsWeek2,
          title: language.schedule_pills_title,
          details: language.schedule_pills_details,
        },
        {
          icon: paperWeek2,
          title: language.schedule_paper_title,
          details: language.schedule_paper_details,
        },
      ],
    },
    week4: {
      style: "week",
      title: language.week4_title,
      backTitle: language.week4_back_title,
      backLink: language.week4_back,
      forwardTitle: language.week4_forward_title,
      forwardLink: language.week4_forward,
      para1: language.week4_para1,
      para2: language.week4_para2,
      footerBox1: language.week4_footer_box_line1,
      footerBox2: language.week4_footer_box_line2,
      icons: [
        {
          icon: doctorWeek4,
          title: language.schedule_doctor_title,
          details: language.schedule_doctor_details,
        },
        {
          icon: ipadWeek4,
          title: language.schedule_ipad_title,
          details: language.schedule_ipad_details,
        },
        {
          icon: thermWeek4,
          title: language.schedule_therm_title,
          details: language.schedule_therm_details,
        },
        {
          icon: scopeWeek4,
          title: language.schedule_scope_title,
          details: language.schedule_scope_details,
        },
        {
          icon: tubeWeek4,
          title: language.schedule_tube_title,
          details: language.schedule_tube_details,
        },
        {
          icon: crossWeek4,
          title: language.schedule_cross_title,
          details: language.schedule_cross_details,
        },
        {
          icon: bottleWeek4,
          title: language.schedule_bottle_title,
          details: language.schedule_bottle_details,
        },
        {
          icon: thumbsWeek4,
          title: language.schedule_thumbs_title,
          details: language.schedule_thumbs_details,
        },
        {
          icon: pillsWeek4,
          title: language.schedule_pills_title,
          details: language.schedule_pills_details,
        },
        {
          icon: paperWeek4,
          title: language.schedule_paper_title,
          details: language.schedule_paper_details,
        },
      ],
    },
    week8: {
      style: "week",
      title: language.week8_title,
      backTitle: language.week8_back_title,
      backLink: language.week8_back,
      forwardTitle: language.week8_forward_title,
      forwardLink: language.week8_forward,
      para1: language.week8_para1,
      para2: language.week8_para2,
      footerBox1: language.week8_footer_box_line1,
      footerBox2: language.week8_footer_box_line2,
      icons: [
        {
          icon: doctorWeek8,
          title: language.schedule_doctor_title,
          details: language.schedule_doctor_details,
        },
        {
          icon: ipadWeek8,
          title: language.schedule_ipad_title,
          details: language.schedule_ipad_details,
        },
        {
          icon: thermWeek8,
          title: language.schedule_therm_title,
          details: language.schedule_therm_details,
        },
        {
          icon: scopeWeek8,
          title: language.schedule_scope_title,
          details: language.schedule_scope_details,
        },
        {
          icon: tubeWeek8,
          title: language.schedule_tube_title,
          details: language.schedule_tube_details,
        },
        {
          icon: crossWeek8,
          title: language.schedule_cross_title,
          details: language.schedule_cross_details,
        },
        {
          icon: bottleWeek8,
          title: language.schedule_bottle_title,
          details: language.schedule_bottle_details,
        },
        {
          icon: thumbsWeek8,
          title: language.schedule_thumbs_title,
          details: language.schedule_thumbs_details,
        },
        {
          icon: pillsWeek8,
          title: language.schedule_pills_title,
          details: language.schedule_pills_details,
        },
        {
          icon: paperWeek8,
          title: language.schedule_paper_title,
          details: language.schedule_paper_details,
        },
      ],
    },
    week12: {
      style: "week",
      title: language.week12_title,
      backTitle: language.week12_back_title,
      backLink: language.week12_back,
      forwardTitle: language.week12_forward_title,
      forwardLink: language.week12_forward,
      para1: language.week12_para1,
      para2: language.week12_para2,
      footerBox1: language.week12_footer_box_line1,
      footerBox2: language.week12_footer_box_line2,
      icons: [
        {
          icon: doctorWeek12,
          title: language.schedule_doctor_title,
          details: language.schedule_doctor_details,
        },
        {
          icon: ipadWeek12,
          title: language.schedule_ipad_title,
          details: language.schedule_ipad_details,
        },
        {
          icon: thermWeek12,
          title: language.schedule_therm_title,
          details: language.schedule_therm_details,
        },
        {
          icon: heartWeek12,
          title: language.schedule_heart_title,
          details: language.schedule_heart_details,
        },
        {
          icon: scopeWeek12,
          title: language.schedule_scope_title,
          details: language.schedule_scope_details,
        },
        {
          icon: breathWeek12,
          title: language.schedule_breath_title,
          details: language.schedule_breath_details,
        },
        {
          icon: eyeWeek12,
          title: language.schedule_eye_title,
          details: language.schedule_eye_details,
        },
        {
          icon: tubeWeek12,
          title: language.schedule_tube_title,
          details: language.schedule_tube_details,
        },
        {
          icon: crossWeek12,
          title: language.schedule_cross_title,
          details: language.schedule_cross_details,
        },
        {
          icon: colonWeek12,
          title: language.schedule_colon_title,
          details: language.schedule_colon_details,
        },
        {
          icon: bottleWeek12,
          title: language.schedule_bottle_title,
          details: language.schedule_bottle_details,
        },
        {
          icon: thumbsWeek12,
          title: language.schedule_thumbs_title,
          details: language.schedule_thumbs_details,
        },
        {
          icon: pillsWeek12,
          title: language.schedule_pills_title,
          details: language.schedule_pills_details,
        },
        {
          icon: paperWeek12,
          title: language.schedule_paper_title,
          details: language.schedule_paper_details,
        },
      ],
    },
    week16: {
      style: "week",
      title: language.week16_title,
      backTitle: language.week16_back_title,
      backLink: language.week16_back,
      forwardTitle: language.week16_forward_title,
      forwardLink: language.week16_forward,
      para1: language.week16_para1,
      para2: language.week16_para2,
      footerBox1: language.week16_footer_box_line1,
      footerBox2: language.week16_footer_box_line2,
      icons: [
        {
          icon: doctorWeek16,
          title: language.schedule_doctor_title,
          details: language.schedule_doctor_details,
        },
        {
          icon: ipadWeek16,
          title: language.schedule_ipad_title,
          details: language.schedule_ipad_details,
        },
        {
          icon: thermWeek16,
          title: language.schedule_therm_title,
          details: language.schedule_therm_details,
        },
        {
          icon: scopeWeek16,
          title: language.schedule_scope_title,
          details: language.schedule_scope_details,
        },
        {
          icon: tubeWeek16,
          title: language.schedule_tube_title,
          details: language.schedule_tube_details,
        },
        {
          icon: bottleWeek16,
          title: language.schedule_bottle_title,
          details: language.schedule_bottle_details,
        },
        {
          icon: thumbsWeek16,
          title: language.schedule_thumbs_title,
          details: language.schedule_thumbs_details,
        },
        {
          icon: pillsWeek16,
          title: language.schedule_pills_title,
          details: language.schedule_pills_details,
        },
        {
          icon: paperWeek16,
          title: language.schedule_paper_title,
          details: language.schedule_paper_details,
        },
      ],
    },
    week20: {
      style: "week",
      title: language.week20_title,
      backTitle: language.week20_back_title,
      backLink: language.week20_back,
      forwardTitle: language.week20_forward_title,
      forwardLink: language.week20_forward,
      para1: language.week20_para1,
      para2: language.week20_para2,
      footerBox1: language.week20_footer_box_line1,
      footerBox2: language.week20_footer_box_line2,
      icons: [
        {
          icon: doctorWeek20,
          title: language.schedule_doctor_title,
          details: language.schedule_doctor_details,
        },
        {
          icon: ipadWeek20,
          title: language.schedule_ipad_title,
          details: language.schedule_ipad_details,
        },
        {
          icon: thermWeek20,
          title: language.schedule_therm_title,
          details: language.schedule_therm_details,
        },
        {
          icon: scopeWeek20,
          title: language.schedule_scope_title,
          details: language.schedule_scope_details,
        },
        {
          icon: tubeWeek20,
          title: language.schedule_tube_title,
          details: language.schedule_tube_details,
        },
        {
          icon: bottleWeek20,
          title: language.schedule_bottle_title,
          details: language.schedule_bottle_details,
        },
        {
          icon: thumbsWeek20,
          title: language.schedule_thumbs_title,
          details: language.schedule_thumbs_details,
        },
        {
          icon: pillsWeek20,
          title: language.schedule_pills_title,
          details: language.schedule_pills_details,
        },
        {
          icon: paperWeek20,
          title: language.schedule_paper_title,
          details: language.schedule_paper_details,
        },
      ],
    },
    week24: {
      style: "week",
      title: language.week24_title,
      backTitle: language.week24_back_title,
      backLink: language.week24_back,
      forwardTitle: language.week24_forward_title,
      forwardLink: language.week24_forward,
      para1: language.week24_para1,
      para2: language.week24_para2,
      footerBox1: language.week24_footer_box_line1,
      footerBox2: language.week24_footer_box_line2,
      icons: [
        {
          icon: doctorWeek24,
          title: language.schedule_doctor_title,
          details: language.schedule_doctor_details,
        },
        {
          icon: ipadWeek24,
          title: language.schedule_ipad_title,
          details: language.schedule_ipad_details,
        },
        {
          icon: thermWeek24,
          title: language.schedule_therm_title,
          details: language.schedule_therm_details,
        },
        {
          icon: scopeWeek24,
          title: language.schedule_scope_title,
          details: language.schedule_scope_details,
        },
        {
          icon: tubeWeek24,
          title: language.schedule_tube_title,
          details: language.schedule_tube_details,
        },
        {
          icon: crossWeek24,
          title: language.schedule_cross_title,
          details: language.schedule_cross_details,
        },
        {
          icon: bottleWeek24,
          title: language.schedule_bottle_title,
          details: language.schedule_bottle_details,
        },
        {
          icon: thumbsWeek24,
          title: language.schedule_thumbs_title,
          details: language.schedule_thumbs_details,
        },
        {
          icon: pillsWeek24,
          title: language.schedule_pills_title,
          details: language.schedule_pills_details,
        },
        {
          icon: paperWeek24,
          title: language.schedule_paper_title,
          details: language.schedule_paper_details,
        },
      ],
    },
    week32: {
      style: "week",
      title: language.week32_title,
      backTitle: language.week32_back_title,
      backLink: language.week32_back,
      forwardTitle: language.week32_forward_title,
      forwardLink: language.week32_forward,
      para1: language.week32_para1,
      para2: language.week32_para2,
      footerBox1: language.week32_footer_box_line1,
      footerBox2: language.week32_footer_box_line2,
      icons: [
        {
          icon: doctorWeek32,
          title: language.schedule_doctor_title,
          details: language.schedule_doctor_details,
        },
        {
          icon: ipadWeek32,
          title: language.schedule_ipad_title,
          details: language.schedule_ipad_details,
        },
        {
          icon: thermWeek32,
          title: language.schedule_therm_title,
          details: language.schedule_therm_details,
        },
        {
          icon: scopeWeek32,
          title: language.schedule_scope_title,
          details: language.schedule_scope_details,
        },
        {
          icon: breathWeek32,
          title: language.schedule_breath_title2,
          details: language.schedule_breath_details,
        },
        {
          icon: tubeWeek32,
          title: language.schedule_tube_title,
          details: language.schedule_tube_details,
        },
        {
          icon: bottleWeek32,
          title: language.schedule_bottle_title,
          details: language.schedule_bottle_details,
        },
        {
          icon: thumbsWeek32,
          title: language.schedule_thumbs_title,
          details: language.schedule_thumbs_details,
        },
        {
          icon: pillsWeek32,
          title: language.schedule_pills_title,
          details: language.schedule_pills_details,
        },
        {
          icon: paperWeek32,
          title: language.schedule_paper_title,
          details: language.schedule_paper_details,
        },
      ],
    },
    week40: {
      style: "week",
      title: language.week40_title,
      backTitle: language.week40_back_title,
      backLink: language.week40_back,
      forwardTitle: language.week40_forward_title,
      forwardLink: language.week40_forward,
      para1: language.week40_para1,
      para2: language.week40_para2,
      footerBox1: language.week40_footer_box_line1,
      footerBox2: language.week40_footer_box_line2,
      icons: [
        {
          icon: doctorWeek40,
          title: language.schedule_doctor_title,
          details: language.schedule_doctor_details,
        },
        {
          icon: ipadWeek40,
          title: language.schedule_ipad_title,
          details: language.schedule_ipad_details,
        },
        {
          icon: thermWeek40,
          title: language.schedule_therm_title,
          details: language.schedule_therm_details,
        },
        {
          icon: scopeWeek40,
          title: language.schedule_scope_title,
          details: language.schedule_scope_details,
        },
        {
          icon: tubeWeek40,
          title: language.schedule_tube_title,
          details: language.schedule_tube_details,
        },
        {
          icon: bottleWeek40,
          title: language.schedule_bottle_title,
          details: language.schedule_bottle_details,
        },
        {
          icon: thumbsWeek40,
          title: language.schedule_thumbs_title,
          details: language.schedule_thumbs_details,
        },
        {
          icon: pillsWeek40,
          title: language.schedule_pills_title,
          details: language.schedule_pills_details,
        },
        {
          icon: paperWeek40,
          title: language.schedule_paper_title,
          details: language.schedule_paper_details,
        },
      ],
    },
    week48: {
      style: "week",
      title: language.week48_title,
      backTitle: language.week48_back_title,
      backLink: language.week48_back,
      forwardTitle: language.week48_forward_title,
      forwardLink: language.week48_forward,
      para1: language.week48_para1,
      para2: language.week48_para2,
      footerBox1: language.week48_footer_box_line1,
      footerBox2: language.week48_footer_box_line2,
      icons: [
        {
          icon: doctorWeek48,
          title: language.schedule_doctor_title,
          details: language.schedule_doctor_details,
        },
        {
          icon: ipadWeek48,
          title: language.schedule_ipad_title,
          details: language.schedule_ipad_details,
        },
        {
          icon: thermWeek48,
          title: language.schedule_therm_title,
          details: language.schedule_therm_details,
        },
        {
          icon: scopeWeek48,
          title: language.schedule_scope_title,
          details: language.schedule_scope_details,
        },
        {
          icon: tubeWeek48,
          title: language.schedule_tube_title,
          details: language.schedule_tube_details,
        },
        {
          icon: bottleWeek48,
          title: language.schedule_bottle_title,
          details: language.schedule_bottle_details,
        },
        {
          icon: thumbsWeek48,
          title: language.schedule_thumbs_title,
          details: language.schedule_thumbs_details,
        },
        {
          icon: pillsWeek48,
          title: language.schedule_pills_title,
          details: language.schedule_pills_details,
        },
        {
          icon: paperWeek48,
          title: language.schedule_paper_title,
          details: language.schedule_paper_details,
        },
      ],
    },
    week52: {
      style: "week",
      title: language.week52_title,
      backTitle: language.week52_back_title,
      backLink: language.week52_back,
      forwardTitle: language.week52_forward_title,
      forwardLink: language.week52_forward,
      para1: language.week52_para1,
      para2: language.week52_para2,
      footerBox1: language.week52_footer_box_line1,
      footerBox2: language.week52_footer_box_line2,
      icons: [
        {
          icon: doctorWeek52,
          title: language.schedule_doctor_title,
          details: language.schedule_doctor_details,
        },
        {
          icon: ipadWeek52,
          title: language.schedule_ipad_title,
          details: language.schedule_ipad_details,
        },
        {
          icon: thermWeek52,
          title: language.schedule_therm_title,
          details: language.schedule_therm_details,
        },
        {
          icon: heartWeek52,
          title: language.schedule_heart_title,
          details: language.schedule_heart_details,
        },
        {
          icon: scopeWeek52,
          title: language.schedule_scope_title,
          details: language.schedule_scope_details,
        },
        {
          icon: physicalWeek52,
          title: language.schedule_physical_title,
          details: language.schedule_physical_details,
        },
        {
          icon: breathWeek52,
          title: language.schedule_breath_title,
          details: language.schedule_breath_details,
        },
        {
          icon: eyeWeek52,
          title: language.schedule_eye_title,
          details: language.schedule_eye_details,
        },
        {
          icon: tubeWeek52,
          title: language.schedule_tube_title,
          details: language.schedule_tube_details,
        },
        {
          icon: crossWeek52,
          title: language.schedule_cross_title,
          details: language.schedule_cross_details,
        },
        {
          icon: colonWeek52,
          title: language.schedule_colon_title,
          details: language.schedule_colon_details,
        },
        {
          icon: bottleWeek52,
          title: language.schedule_bottle_title,
          details: language.schedule_bottle_details,
        },
        {
          icon: thumbsWeek52,
          title: language.schedule_thumbs_title,
          details: language.schedule_thumbs_details,
        },
        {
          icon: pillsWeek52,
          title: language.schedule_pills_title2,
          details: language.schedule_pills_details,
        },
        {
          icon: paperWeek52,
          title: language.schedule_paper_title,
          details: language.schedule_paper_details,
        },
      ],
    },
    followup1: {
      style: "week",
      title: language.followup1_title,
      backTitle: language.followup1_back_title,
      backLink: language.followup1_back,
      forwardTitle: language.followup1_forward_title,
      forwardLink: language.followup1_forward,
      para1: language.followup1_para1,
      para2: language.followup1_para2,
      footerBox1: language.followup1_footer_box_line1,
      footerBox2: language.followup1_footer_box_line2,
      icons: [
        {
          icon: thermFollowup1,
          title: language.schedule_therm_title,
          details: language.schedule_therm_details,
        },
        {
          icon: scopeFollowup1,
          title: language.schedule_scope_title,
          details: language.schedule_scope_details,
        },
        {
          icon: breathFollowup1,
          title: language.schedule_breath_title,
          details: language.schedule_breath_details,
        },
        {
          icon: eyeFollowup1,
          title: language.schedule_eye_title,
          details: language.schedule_eye_details,
        },
        {
          icon: tubeFollowup1,
          title: language.schedule_tube_title,
          details: language.schedule_tube_details,
        },
        {
          icon: bottleFollowup1,
          title: language.schedule_bottle_title,
          details: language.schedule_bottle_details,
        },
        {
          icon: thumbsFollowup1,
          title: language.schedule_thumbs_title,
          details: language.schedule_thumbs_details,
        },
      ],
    },
    followup2: {
      style: "week",
      title: language.followup2_title,
      backTitle: language.followup2_back_title,
      backLink: language.followup2_back,
      forwardTitle: language.followup2_forward_title,
      forwardLink: language.followup2_forward,
      para1: language.followup2_para1,
      para2: language.followup2_para2,
      footerBox1: language.followup2_footer_box_line1,
      footerBox2: language.followup2_footer_box_line2,
      icons: [
        {
          icon: thermFollowup2,
          title: language.schedule_therm_title,
          details: language.schedule_therm_details,
        },
        {
          icon: scopeFollowup2,
          title: language.schedule_scope_title,
          details: language.schedule_scope_details,
        },
        {
          icon: tubeFollowup2,
          title: language.schedule_tube_title,
          details: language.schedule_tube_details,
        },
        {
          icon: bottleFollowup2,
          title: language.schedule_bottle_title,
          details: language.schedule_bottle_details,
        },
        {
          icon: thumbsFollowup2,
          title: language.schedule_thumbs_title,
          details: language.schedule_thumbs_details,
        },
      ],
    },
  };
}
