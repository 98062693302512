import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import { Box } from "@mui/material";

import Home from "./routes/Home";
import Welcome from "./routes/Welcome";
import Overview from "./routes/Overview";
import Responsibilities from "./routes/Responsibilities";
import Schedule from "./routes/Schedule";
import Contact from "./routes/Contact";

import MainNav from "./components/MainNav";

import { useAdpContextController } from "./context";

// Cache Clearing
import withClearCache from "./clearCache";

import "./App.css";

function MainApp() {
  const [controller] = useAdpContextController();
  const { language, flag } = controller;

  const container = {
    backgroundColor: "#e6e3db",
    maxWidth: "1200px",
    mx: "auto",
  };

  useEffect(() => {
    document.title = language.prg_title;
    window.localStorage.setItem("savedLang", language.utm_code);
  }, [language]);

  return (
    <div className="App">
      <Box sx={container}>
        <Router>
          <div>
            <MainNav />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/welcome" element={<Welcome />} />
              <Route path="/study-overview" element={<Overview />} />
              <Route
                path="/study-responsibilities"
                element={<Responsibilities />}
              />
              <Route path="/schedule" element={<Schedule />} />
              <Route path="/contact" element={<Contact />} />
            </Routes>
          </div>
        </Router>
      </Box>
    </div>
  );
}

const ClearCacheComponent = withClearCache(MainApp);
function App() {
  return <ClearCacheComponent />;
}

export default App;
