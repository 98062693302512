// translation imports
import us_eng from "./translations/us_eng.json";
import us_esp from "./translations/us_esp.json";
import de_deu from "./translations/de_deu.json";
import pl_pol from "./translations/pl_pol.json";
import es_esp from "./translations/es_esp.json";
import ca_esp from "./translations/ca_esp.json";

// Flag imports
import UsEng from "../assets/images/flags/united-states-of-america.png";
import DeDeu from "../assets/images/flags/austria.png";
import PlPol from "../assets/images/flags/republic-of-poland.png";
import EsEsp from "../assets/images/flags/spain.png";

const supportedLanguages = {
  us_eng: {
    json: us_eng,
    iso_country_code: "USA",
    flag: UsEng,
  },
  us_esp: {
    json: us_esp,
    iso_country_code: "USA",
    flag: UsEng,
  },
  de_aut: {
    json: de_deu,
    iso_country_code: "AUT",
    flag: DeDeu,
  },
  pl_pol: {
    json: pl_pol,
    iso_country_code: "POL",
    flag: PlPol,
  },
  es_esp: {
    json: es_esp,
    iso_country_code: "ESP",
    flag: EsEsp,
  },
  ca_esp: {
    json: ca_esp,
    iso_country_code: "ESP",
    flag: EsEsp,
  },
};

const activeLangs = [
  {
    name: "USA - ENGLISH",
    json: us_eng,
    utm_code: "us_eng",
    flag: UsEng,
  },
  {
    name: "USA - SPANISH",
    json: us_esp,
    utm_code: "us_esp",
    flag: UsEng,
  },
  {
    name: "AUT - GERMAN",
    json: de_deu,
    utm_code: "de_deu",
    flag: DeDeu,
  },
  {
    name: "POL - POLISH",
    json: pl_pol,
    utm_code: "pl_pol",
    flag: PlPol,
  },
  {
    name: "ESP - SPANISH",
    json: es_esp,
    utm_code: "es_esp",
    flag: EsEsp,
  },
  {
    name: "ESP - CATALAN",
    json: ca_esp,
    utm_code: "ca_esp",
    flag: EsEsp,
  },
];

export { supportedLanguages, activeLangs };
