// react imports
import { createContext, useContext, useReducer, useMemo } from "react";

// translation imports
import us_eng from "./translations/us_eng.json";

// Consts imports
import { supportedLanguages, activeLangs } from "./consts";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Main context
const AdpContext = createContext();

// Setting custom name for the context which is visible on react dev tools
AdpContext.displayName = "AdpContext";

// React reducer
function reducer(state, action) {
  switch (action.type) {
    case "LANGUAGE": {
      return { ...state, language: action.value };
    }
    case "LANG_LIST": {
      return { ...state, langList: action.value };
    }
    case "FLAG": {
      return { ...state, flag: action.value };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

const initialLanguage = () => {
  const savedLang = window.localStorage.getItem("savedLang");

  const utm_code = new URLSearchParams(window.location.search).get(
    "utm_campaign"
  );

  if (utm_code === null && savedLang !== null) {
    return supportedLanguages[savedLang].json;
  }

  if (utm_code === null && savedLang === null) {
    return us_eng;
  }

  if (utm_code in supportedLanguages) {
    const language = supportedLanguages[utm_code].json;

    return language;
  }
};

const initialFlag = () => {
  const savedFlag = window.localStorage.getItem("savedFlag");

  const utm_code = new URLSearchParams(window.location.search).get(
    "utm_campaign"
  );

  if (utm_code === null && savedFlag !== null) {
    return savedFlag;
  }

  if (utm_code === null && savedFlag === null) {
    return us_eng;
  }

  if (utm_code in supportedLanguages) {
    const flagSet = supportedLanguages[utm_code].flag;

    return flagSet;
  }
};

// React context provider
function AdpContextControllerProvider({ children }) {
  const initialState = {
    language: initialLanguage(),
    langList: activeLangs,
    flag: initialFlag(),
  };

  const [controller, dispatch] = useReducer(reducer, initialState);

  const value = useMemo(() => [controller, dispatch], [controller, dispatch]);

  return <AdpContext.Provider value={value}>{children}</AdpContext.Provider>;
}

// React custom hook for using context
function useAdpContextController() {
  const context = useContext(AdpContext);

  if (!context) {
    throw new Error(
      "useAdpContextController should be used inside the AdpContextControllerProvider."
    );
  }

  return context;
}

// Typechecking props for the MaterialUIControllerProvider
AdpContextControllerProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

// Context module functions
const setLanguage = (dispatch, value) => dispatch({ type: "LANGUAGE", value });
const setLangList = (dispatch, value) => dispatch({ type: "LANG_LIST", value });
const setFlag = (dispatch, value) => dispatch({ type: "FLAG", value });

export {
  AdpContextControllerProvider,
  useAdpContextController,
  setLanguage,
  setLangList,
  setFlag,
};
