import React, { createRef, useEffect } from "react";

import { Box, Grid, Typography } from "@mui/material";

import { useAdpContextController } from "../context";

import lottie from "lottie-web";

import animation from "../assets/animJson/06_HallPass.json";

function Welcome() {
  const [controller] = useAdpContextController();
  const { language } = controller;

  const animationContainer = createRef();

  useEffect(() => {
    const anim = lottie.loadAnimation({
      container: animationContainer.current,
      loop: false,
      autoplay: true,
      animationData: animation,
    });

    return () => anim.destroy();
  }, []);

  const container = {
    backgroundColor: "#e6e3db",
    height: "auto",
  };

  const divider = {
    mx: "auto",
    height: "1px",
    width: "75%",
    backgroundColor: "#333333",
  };

  const column1 = {
    p: { xs: "1rem", sm: "3rem", md: "5rem" },
  };

  const column2 = {
    mt: { xs: "1rem", sm: "5rem" },
  };

  const header1 = {
    fontFamily: "brandon-grotesque",
    fontSize: { xs: "2.5rem", sm: "2rem", md: "2.5rem" },
    fontWeight: "bold",
    color: "#333333",
    mb: "1rem",
  };

  const para = {
    fontFamily: "ff-basic-gothic-pro",
    textAlign: "left",
    my: "1rem",
    fontSize: { xs: "1rem", sm: ".75rem", md: "1rem" },
    color: "#333333",
  };

  return (
    <Box sx={container}>
      <Grid container p={2}>
        <Grid item xs={12} sm={7} sx={column1}>
          <Typography variant="h1" component="h1" sx={header1}>
            {language.welcome_title}
          </Typography>
          <Box sx={divider} />
          <Typography component="p" sx={para}>
            {language.welcome_para1}
          </Typography>
          <Typography component="p" sx={para}>
            {language.welcome_para2}
          </Typography>
          <Typography component="p" sx={para}>
            {language.welcome_para3}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={5} sx={column2}>
          <div style={{ height: "auto" }} ref={animationContainer} />
          {/* <img src={Graphic} alt="Welcome page graphic" style={image} /> */}
        </Grid>
      </Grid>
    </Box>
  );
}

export default Welcome;
