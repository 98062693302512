import React from "react";

import { Link } from "react-router-dom";

import { Box, Typography } from "@mui/material";

import { useAdpContextController } from "../context";

import homeBG from "../assets/images/home_image.png";
import homeBGMobile from "../assets/images/home_image_mobile.png";

function Home() {
  const [controller] = useAdpContextController();
  const { language } = controller;

  const boxStyle = {
    color: "#fff",
    width: "auto",
    height: "1000px",
    backgroundImage: `url(${homeBG})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "top",
    backgroundSize: "100%",
  };

  const boxStyleMobile = {
    color: "#fff",
    width: "auto",
    height: "1000px",
    backgroundImage: `url(${homeBGMobile})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "top",
    backgroundSize: "100%",
  };

  const wrapper = {
    mx: "auto",
    paddingLeft: { xs: "1rem", sm: "10rem", md: "15rem" },
    paddingTop: { xs: "1rem", sm: "5rem", md: "10rem" },
  };

  const wrapperMobile = {
    mx: "auto",
    p: "2rem",
  };

  const displayDesktop = {
    display: { xs: "none", sm: "block" },
  };

  const displayMobile = {
    display: { xs: "block", sm: "none" },
  };

  const link = {
    color: "#d46e28",
  };

  const fontSizes = {
    header1: {
      desktop: language.name === "Spanish" ? "35px" : "48px",
      mobile: language.name === "Spanish" ? "25px" : "30px",
    },
    header2: {
      desktop: language.name === "Spanish" ? "1.5rem" : "1.5rem",
      mobile: language.name === "Spanish" ? ".6rem" : ".7rem",
    },
    header3: {
      desktop: language.name === "Spanish" ? "1rem" : "1rem",
      mobile: language.name === "Spanish" ? ".9rem" : "1rem",
    },
  };

  const header1 = {
    fontFamily: "brandon-grotesque",
    fontWeight: "bold",
    fontSize: fontSizes.header1.desktop,
    color: "#DEDDDA",
  };

  const header2 = {
    fontFamily: "ff-basic-gothic-pro",
    fontWeight: "bold",
    fontSize: fontSizes.header2.desktop,
  };

  const header3 = {
    fontFamily: "ff-basic-gothic-pro",
    fontWeight: "bold",
    fontSize: fontSizes.header3.desktop,
    mt: ".5rem",
  };

  const divider = {
    height: "1px",
    backgroundColor: "#fff",
    mx: "auto",
    my: "1rem",
    width: "50%",
  };

  const header1Mobile = {
    fontFamily: "brandon-grotesque",
    fontWeight: "bold",
    fontSize: fontSizes.header1.mobile,
    color: "#DEDDDA",
  };

  const header2Mobile = {
    fontFamily: "ff-basic-gothic-pro",
    fontWeight: "bold",
    fontSize: fontSizes.header2.mobile,
  };

  const header3Mobile = {
    fontFamily: "ff-basic-gothic-pro",
    fontWeight: "bold",
    fontSize: fontSizes.header3.mobile,
    mt: ".5rem",
  };

  const dividerMobile = {
    height: "1px",
    backgroundColor: "#fff",
    mx: "auto",
    my: "1rem",
    width: "50%",
  };

  return (
    <>
      <Box sx={displayDesktop}>
        <Box sx={boxStyle}>
          <Box sx={wrapper}>
            <Typography variant="h1" component="h1" sx={header1}>
              {language.home_title1}
            </Typography>
            <Typography variant="h1" component="h2" sx={header1}>
              {language.home_title2}
            </Typography>
            <Box sx={divider}></Box>
            <Typography variant="h1" component="h2" sx={header2}>
              {language.home_title3}
            </Typography>
            <Typography variant="h2" component="h3" sx={header3}>
              {language.home_pre_link}
              <Link to="/welcome" style={link}>
                {language.home_link}
              </Link>
              {language.home_post_link}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box sx={displayMobile}>
        <Box sx={boxStyleMobile}>
          <Box sx={wrapperMobile}>
            <Typography variant="h1" component="h1" sx={header1Mobile}>
              {language.home_title1}
            </Typography>
            <Typography variant="h1" component="h2" sx={header1Mobile}>
              {language.home_title2}
            </Typography>
            <Box sx={dividerMobile}></Box>
            <Typography variant="h1" component="h2" sx={header2Mobile}>
              {language.home_title3}
            </Typography>
            <Typography variant="h2" component="h3" sx={header3Mobile}>
              {language.home_pre_link}
              <Link to="/welcome" style={link}>
                {language.home_link}
              </Link>
              {language.home_post_link}
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default Home;
