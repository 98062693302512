import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { Box, Typography, IconButton, Drawer } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";

import LanguageSwitcher from "./LanguageSwitcher";

import { useAdpContextController } from "../context";

import chalkboard from "../assets/images/header_bg.png";

function MainNav() {
  const [controller] = useAdpContextController();
  const { language } = controller;

  const navigate = useNavigate();

  const [activeLink, setActiveLink] = useState(window.location.pathname);
  const [open, setState] = useState(false);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState(open);
  };

  const fontSizes = {
    desktop:
      language.name === "Spanish" || language.name === "Catalan"
        ? { sm: "1rem", md: "1.35rem" }
        : { sm: "1.5rem", md: "1.75rem" },
    mobile:
      language.name === "Spanish" || language.name === "Catalan"
        ? { sm: "1rem" }
        : { sm: "1.5rem", md: "1.75rem" },
  };

  const mainNavLink = {
    display: "inline",
    marginLeft: "2rem",
    fontFamily: "brandon-grotesque",
    fontWeight: "bold",
    fontSize: fontSizes.desktop,
    color: "#DEDDDA",
    textDecoration: "none",
    cursor: "pointer",
  };

  const activeNavLink = {
    display: "inline",
    marginLeft: "2rem",
    fontFamily: "brandon-grotesque",
    fontWeight: "bold",
    fontSize: fontSizes.desktop,
    color: "#d46e28",
    textDecoration: "underline",
    cursor: "pointer",
  };

  const mobileNavLink = {
    marginLeft: "2rem",
    fontFamily: "brandon-grotesque",
    fontWeight: "bold",
    fontSize: fontSizes.mobile,
    color: "#333333",
    textDecoration: "none",
    cursor: "pointer",
  };

  const activeNavLinkMobile = {
    marginLeft: "2rem",
    fontFamily: "brandon-grotesque",
    fontWeight: "bold",
    fontSize: fontSizes.mobile,
    color: "#d46e28",
    textDecoration: "underline",
    cursor: "pointer",
  };

  const container = {
    height: "145px",
    backgroundImage: `url(${chalkboard})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "top",
    backgroundSize: "1200px 150px",
    display: "block",
  };

  const containerMobile = {
    height: "75px",
    backgroundImage: `url(${chalkboard})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "top",
    backgroundSize: "1200px 150px",
    display: "block",
  };

  const navLinks = {
    py: "1rem",
    mx: "auto",
    width: { sm: "90%", md: "80%" },
    height: "5rem",
  };

  const displayDesktop = {
    display: { xs: "none", sm: "block" },
  };

  const displayMobile = {
    display: { xs: "block", sm: "none" },
  };

  const navSwitcher = (x) => {
    setActiveLink(x);
    navigate(x);
  };

  return (
    <>
      <Box sx={displayDesktop}>
        <Box sx={container}>
          <Box sx={{ float: "right", p: "2rem" }}>
            <LanguageSwitcher mobile={false} />
          </Box>
          <Box sx={navLinks}>
            <Typography
              component="h1"
              sx={activeLink === "/welcome" ? activeNavLink : mainNavLink}
              onClick={() => {
                window.localStorage.removeItem("savedState");
                navSwitcher("/welcome");
              }}
            >
              {language.navbar_welcome}
            </Typography>
            <Typography
              component="h1"
              sx={
                activeLink === "/study-overview" ? activeNavLink : mainNavLink
              }
              onClick={() => {
                window.localStorage.removeItem("savedState");
                navSwitcher("/study-overview");
              }}
            >
              {language.navbar_overview}
            </Typography>

            <Typography
              component="h1"
              sx={
                activeLink === "/study-responsibilities"
                  ? activeNavLink
                  : mainNavLink
              }
              onClick={() => {
                window.localStorage.removeItem("savedState");
                navSwitcher("/study-responsibilities");
              }}
            >
              {language.navbar_responsibilities}
            </Typography>
            <Box>
              <Typography
                component="h1"
                sx={activeLink === "/schedule" ? activeNavLink : mainNavLink}
                onClick={() => {
                  window.localStorage.removeItem("savedState");
                  navSwitcher("/schedule");
                }}
              >
                {language.navbar_schedule}
              </Typography>

              <Typography
                component="h1"
                sx={activeLink === "/contact" ? activeNavLink : mainNavLink}
                onClick={() => {
                  window.localStorage.removeItem("savedState");
                  navSwitcher("/contact");
                }}
              >
                {language.navbar_contact}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box sx={displayMobile}>
        <LanguageSwitcher mobile />
        <Box sx={containerMobile}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={toggleDrawer(true)}
            sx={{
              p: "1.5rem",
              color: "#fff",
              display: {
                xs: "block",
                sm: "none",
              },
            }}
          >
            <MenuIcon />
          </IconButton>

          <Drawer
            anchor="left"
            open={open}
            onClose={toggleDrawer(false)}
            onOpen={toggleDrawer(true)}
          >
            <Box
              sx={{
                p: 2,
                height: 1,
                backgroundColor: "#e6e3db",
              }}
            >
              {/* when clicking the icon it calls the function toggleDrawer and closes the drawer by setting the variable open to false */}
              <IconButton sx={{ mb: 2, display: "block" }}>
                <CloseIcon onClick={toggleDrawer(false)} />
              </IconButton>
              <Typography
                component="h1"
                sx={activeLink === "/" ? activeNavLinkMobile : mobileNavLink}
                onClick={() => {
                  window.localStorage.removeItem("savedState");

                  navSwitcher("/");
                  setState(false);
                }}
              >
                {language.navbar_home}
              </Typography>
              <Typography
                component="h1"
                sx={
                  activeLink === "/welcome"
                    ? activeNavLinkMobile
                    : mobileNavLink
                }
                onClick={() => {
                  window.localStorage.removeItem("savedState");

                  navSwitcher("/welcome");
                  setState(false);
                }}
              >
                {language.navbar_welcome}
              </Typography>
              <Typography
                component="h1"
                sx={
                  activeLink === "/study-overview"
                    ? activeNavLinkMobile
                    : mobileNavLink
                }
                onClick={() => {
                  window.localStorage.removeItem("savedState");

                  navSwitcher("/study-overview");
                  setState(false);
                }}
              >
                {language.navbar_overview}
              </Typography>
              <Typography
                component="h1"
                sx={
                  activeLink === "/study-responsibilities"
                    ? activeNavLinkMobile
                    : mobileNavLink
                }
                onClick={() => {
                  window.localStorage.removeItem("savedState");

                  navSwitcher("/study-responsibilities");
                  setState(false);
                }}
              >
                {language.navbar_responsibilities}
              </Typography>
              <Typography
                component="h1"
                sx={
                  activeLink === "/schedule"
                    ? activeNavLinkMobile
                    : mobileNavLink
                }
                onClick={() => {
                  window.localStorage.removeItem("savedState");

                  navSwitcher("/schedule");
                  setState(false);
                }}
              >
                {language.navbar_schedule}
              </Typography>
              <Typography
                component="h1"
                sx={
                  activeLink === "/contact"
                    ? activeNavLinkMobile
                    : mobileNavLink
                }
                onClick={() => {
                  window.localStorage.removeItem("savedState");

                  navSwitcher("/contact");
                  setState(false);
                }}
              >
                {language.navbar_contact}
              </Typography>
            </Box>
          </Drawer>
        </Box>
      </Box>
    </>
  );
}

export default MainNav;
