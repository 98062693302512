import * as React from "react";
import { Button } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

// Consts import
import { supportedLanguages } from "../context/consts";

import { useAdpContextController, setLanguage, setFlag } from "../context";

import UsEng from "../assets/images/flags/united-states-of-america.png";
// import SpaEs from "../assets/images/flags/spain.png";

// translation imports
import us_eng from "../context/translations/us_eng.json";
import us_esp from "../context/translations/us_esp.json";

export default function LanguageSwitcher({ mobile }) {
  const [controller, dispatch] = useAdpContextController();
  const { language, langList, flag } = controller;

  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    console.log("TARGET >>>", event.currentTarget);
    console.log("EVENT >>>", event);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSwitch = (lang, img) => {
    setAnchorEl(null);
    setFlag(dispatch, img);
    setLanguage(dispatch, lang);
  };

  const css = {
    desktop: {
      backgroundColor: "transparent",
      color: "white",
      "&:hover": {
        color: "black",
        backgroundColor: "white",
      },
    },
    mobile: {
      backgroundColor: "transparent",
      color: "black",
    },
  };

  return (
    <>
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        sx={mobile ? css.mobile : css.desktop}
      >
        <img
          src={supportedLanguages[language.utm_code].flag}
          alt="flag for set language"
          style={{
            padding: ".5rem",
          }}
        />
        {language.country_code} - {language.name}
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {langList.map((lang) => (
          <MenuItem
            key={lang.name}
            onClick={() => {
              handleSwitch(lang.json, lang.flag);
            }}
          >
            {" "}
            <img
              src={lang.flag}
              alt={`${lang.json.country_code} flag`}
              style={{
                padding: ".5rem",
              }}
            />
            {lang.name}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
